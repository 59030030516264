import type { FieldFunctionOptions, StoreObject } from "@apollo/client";

import type {
  GetEobsQueryV2QueryVariables,
  QueryIssuesV2QueryVariables,
} from "@medbillai/graphql-types";

export const mergeEOBS: (
  existing: StoreObject[] | undefined,
  incoming: StoreObject[] | undefined,
  options: FieldFunctionOptions<GetEobsQueryV2QueryVariables>,
) => StoreObject[] | undefined = (existing, incoming, { args, readField }) => {
  const merged = existing ? existing.slice(0) : [];
  const incomingSet = incoming ?? [];
  const after = args?.after ?? 0;

  for (let i = 0; i < incomingSet.length; ++i) {
    const item = incomingSet[i];
    if (item !== undefined) {
      merged[after + i] = item;
    }
  }

  const mergedFilter = [];
  const mergedIdSet = new Set();
  // Edge Case: If the order of EOBs changes between the initial fetch and subsequent fetches,
  // there may be duplicate IDs in the list. This logic ensures any duplicates are handled.
  // Note: The next request will still work correctly because the `after` variable is recalculated
  // based on the current list length, not the previously returned value.
  for (const item of merged) {
    const itemId = readField("id", item);
    if (!mergedIdSet.has(itemId)) {
      mergedIdSet.add(itemId);
      mergedFilter.push(item);
    }
  }
  return mergedFilter;
};

export const mergeIssues: (
  existing: StoreObject[] | undefined,
  incoming: StoreObject[] | undefined,
  options: FieldFunctionOptions<QueryIssuesV2QueryVariables>,
) => StoreObject[] | undefined = (existing, incoming, { readField }) => {
  const merged = existing ? existing.slice(0) : [];
  const existingIdSet = new Set(merged.map(issue => readField("id", issue)));
  // Remove incoming tasks already present in the existing data.
  const incomingAdjusted = incoming
    ? incoming.filter(task => !existingIdSet.has(readField("id", task)))
    : [];
  // This is a group of all of the items that fit our search for everything, so
  // we can update this response and it will directly impact the read results
  merged.push(...incomingAdjusted);
  return merged;
};

/**
 * This theoreticlly will lose performance as it gets bigger, but for the sake
 * of having concurrent updates accross all filters, the case, and the cases screen
 * i think it is worth having. We could implement ID ejection to shrink the
 * total size and not keep the extra paginated values if this is a concern.
 */
export const readIssues: (
  existing: StoreObject[] | undefined,
  options: FieldFunctionOptions<QueryIssuesV2QueryVariables>,
) => StoreObject[] | undefined = (existing, { args, readField }) => {
  if (!existing) {
    return;
  }
  const current = existing ? existing.slice(0) : [];

  const { closed, unread, open } = args || {};

  const filteredItems = current.filter((issue: StoreObject) => {
    const closedAt = readField("closedAt", issue);
    const firstUnseenEvent = readField("firstUnseenEvent", issue);
    if (closed === true) {
      return Boolean(closedAt);
    } else if (unread === true) {
      return Boolean(firstUnseenEvent);
    } else if (open === true) {
      return !closedAt || Boolean(firstUnseenEvent);
    }
    return true; // No filter applied if none of the arguments are true (All case)
  });

  const sortedItems = filteredItems.sort((a: StoreObject, b: StoreObject) => {
    const dateA = new Date(
      readField("sentAt", readField("lastEvent", a)) || 0,
    ).getTime();
    const dateB = new Date(
      readField("sentAt", readField("lastEvent", b)) || 0,
    ).getTime();

    if (dateB - dateA !== 0) {
      return dateB - dateA;
    }
    return 0;
  });

  return sortedItems;
};
