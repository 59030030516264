export async function trackEmailClick(cioLinkId: string) {
  try {
    const postUrl = `https://mail.grantedhealth.com/click/${cioLinkId}`;
    await fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Error posting email click analytics", error);
  }
}
